
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable no-script-url */
export const formatter = (pData, setTableLoader) => {
  pData.forEach((elm) => {
    elm.count = 0;

    if (elm.products) {
      elm.products.forEach((pro) => {
        elm[pro] = `y`;
      });
    }
  });
  setTableLoader(false);
  return pData;
};
export const columnFormattar = (column, onFileSelect) => {

  column.forEach(elm => {
    if (elm.selector == "u_file") {
      elm.cell = row => {

        return (<input type="file" id="myfile" onChange={onFileSelect.bind(null, row)} name="myfile"></input>)
      }
    }
  })
};

