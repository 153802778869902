import { useState, useEffect } from "react";
import CentralizedData from "../../stores/data";
import ServerTable from "../../components/atoms/serverTable";
import { useLocation } from 'react-router-dom';
import http from "../../common/http";
import CustomSearch from "../search";
import { formatter, columnFormattar, saveAllChanges } from './helper'
let initialFilter = {
    isTotalNeeded: true,
    coll: "transaction_details",
    filter: {},
};
let apiURL = 'loadMorePageData';
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable no-script-url */
let catMap = {};
let defaultCatObj = {};
export default function NWSByVendor() {
    const location = useLocation();
    const bData = CentralizedData.backend_data;
    const [column, setColumn] = useState(bData.routeJson.nws.group.nws_by_vendor.column);
    const [filter, setFilter] = useState(initialFilter);
    useEffect(() => {
        if (!column.length) {
            const setData = (err) => {
                // TODO handle error
                setColumn(bData.routeJson.nws.group.nws_by_vendor.column);
            };
            http.getInitialData(setData);
        }
        return () => {
            catMap = {};
            defaultCatObj = {};
        }
    });
    if (!column.length) {
        return <div></div>;
    }
    const subscriber = (searchFilter, api) => {
        apiURL = api;
        setFilter(searchFilter);
    };
    const isBlankPresent = bData.catData[bData.catData.length - 1].cat === 'blank'
    bData.catData.forEach((elm) => {
        catMap[elm.cat] = elm;
        if (elm.sub && !isBlankPresent) {
            elm.sub.push({ cat: 'blank' })
        }
    });
    if (!isBlankPresent) {
        bData.catData.push({ cat: 'blank' })
    }
    const onSelectionChange = (catObj, key) => {
        if (key === `def_cat`) {
            catObj.def_sub_cat = null;
        }
        if (catObj[key] === "blank") {
            catObj[key] = null;
        }

        const vName = catObj.c_vendor_name || (catObj.custom_field && catObj.custom_field.vendor_name_cleaned) || ``
        const vendorName = vName.substring(0, 50).replace(/ /g, ``);
        // const vendorName = (catObj.c_vendor_name && catObj.c_vendor_name.substring(0, 50).replace(/ /g, "")) || ``;
        const vKey = catObj.category_id + "_" + vendorName;
        if (!defaultCatObj[vKey]) {
            defaultCatObj[vKey] = { v_key: vendorName };
        }
        const obj = defaultCatObj[vKey];
        obj.v_def_cat = catObj.def_cat;
        obj.v_def_sub_cat = catObj.def_sub_cat;
        obj.v_def_nws = catObj.def_nws;
    };
    const onSaveClick = () => {
        saveAllChanges(defaultCatObj)
    }
    columnFormattar(column, bData.catData, catMap, onSelectionChange)
    const currentPage = location.state && location.state.currentPage;
    return (
        <div>
            <CustomSearch currentPage={currentPage} subscriber={subscriber}></CustomSearch>
            <div className="sm:col-span-1 right-align">
                <button onClick={onSaveClick}
                    type="button"
                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 "
                >
                    Save All Changes
                </button>
            </div>
            <ServerTable filters={filter} formatter={formatter} columns={column} apiURL={apiURL}></ServerTable>
        </div>
    );
}
