import http from "../../common/http";
const onUpload = (imageObj, cb) => {
    const formData = new FormData();
    for (const key in imageObj) {
        const file = imageObj[key].icon[0];
        formData.append(key, file, file.name);
    }
    http.makeCall(`/saveSettingsIcon`, `post`, formData, (err, res) => {
        if (err) {
            http.errorMsg = err.message || "Something went wrong";
            return console.log(`error = `, err);
        }
        const iconName = res.iconName;
        for (const key in iconName) {
            const obj = this.imageObj[key];
            obj.icon = iconName[key];
        }
        cb();

    });
}
const addDataToFirebase = (type, data, cb) => {
    const dataToSend = { type: `custom_cat` };
    if (type === 2) {
        dataToSend.type = `manual_account`;
        dataToSend.list = data;
    } else {
        dataToSend.list = JSON.parse(JSON.stringify(data));
        dataToSend.list.forEach((elm) => {
            delete elm.lsArr;
        });
    }

    http.makeCall(`/saveSettings`, `post`, dataToSend, (err, res) => {
        if (err) {
            http.errorMsg = err.message || "Something went wrong";
            return console.log(`error = `, err);
        }
        http.notification = 'Updated successfully'
        cb();

    });
}
export const saveAllChanges = (type, imageObj, data, cb) => {
    const imageLen = Object.keys(imageObj).length;
    if (imageLen) {
        return onUpload(imageObj, addDataToFirebase.bind(null, type, data, cb));
    }
    addDataToFirebase(type, data, cb);
}
export const getFilename = (sub) => {
    if (sub) {
        let name = ''
        if (typeof sub.icon === 'object') {
            const iObj = sub.icon[0]
            if (iObj) {
                name = iObj.name
            }
        } else {
            name = sub.icon
        }
        return name
    }
    return ''
}