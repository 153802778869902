import { useEffect, useState } from "react";
import CentralizedData from "../../stores/data";
import ServerTable from "../../components/atoms/serverTable";
import CustomSearch from "../search";
import { useLocation } from "react-router-dom";
const dateFormat = `en-US`;
const dateOptions = { year: `numeric`, month: `long`, day: `numeric`, hour: `numeric`, minute: `numeric` };
const initialFilter = {
  isTotalNeeded: true,
  coll: "account_details",
  filter: {},
};
let apiURL = "loadMorePageData";
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable no-script-url */
const formatter = (pData, setTableLoader) => {
  const arr = [];
  pData.forEach((element) => {
    element.created_on = element.created_on
      ? new Date(element.created_on).toLocaleDateString(dateFormat, dateOptions)
      : ``;
    element.last_sync = element.last_sync
      ? new Date(element.last_sync).toLocaleDateString(dateFormat, dateOptions)
      : ``;
    const c_bank_name =
      (element.c_details && element.c_details.c_bank_name) ||
      element.c_bank_name ||
      element.bank_name;
    element.accounts.forEach((accElm) => {
      accElm.cid = element.cid;
      for (const key in accElm.balances) {
        if (accElm.balances[key] !== null) {
          accElm[key] = accElm.balances[key];
        }
      }
      accElm.created_on = element.created_on;
      accElm.last_sync = element.last_sync;
      accElm.bank_name = c_bank_name;
      arr.push(accElm)
      delete accElm.balances;
    });
  });
  setTableLoader(false)
  return arr;
};
export default function Accounts() {
  const location = useLocation();
  const bData = CentralizedData.backend_data;
  const currentPage = location.state && location.state.currentPage;
  const cRef = bData.routeJson[currentPage] || {}
  const column = cRef.column || [];
  const [filter, setFilter] = useState(initialFilter);
  useEffect(() => { }, []);
  if (!column.length) {
    return <div></div>;
  }
  const subscriber = (searchFilter, api) => {
    apiURL = api;
    setFilter(searchFilter);
  };

  return (
    <div>
      <CustomSearch
        currentPage={currentPage}
        subscriber={subscriber}
      ></CustomSearch>
      <ServerTable
        filters={filter}
        formatter={formatter}
        columns={column}
        apiURL={apiURL}
      ></ServerTable>
    </div>
  );
}
