import React, { useState } from 'react';
import CustomInput from "../../../components/form_elments/customInput";
const container = {}
const AddCategory = ({ catData, loadTable }) => {
    const [showCatInput, setShowCatInput] = useState(false)
    const showForm = () => {
        if (showCatInput && container.value) {
            catData.push({ cat: container.value, sub: [], lsArr: [] });
            container.value = ''
            loadTable()
        }
        setShowCatInput(!showCatInput)
    }
    const getInput = () => {
        if (showCatInput) {
            return <CustomInput container={container} keyName="value" placeholder="Enter New Category name"></CustomInput>
        }
        return ''
    }
    return (
        <div className="flex justify-end w-full">
            {getInput()}
            &nbsp;&nbsp;&nbsp;
            <button className="flex justify-end items-center px-3 py-1 border border-red-500 rounded hover:bg-gray-200" onClick={showForm}>

                {showCatInput ? 'Save' : 'Add'} New Categories
            </button>
        </div>
    );
}

export default AddCategory;
