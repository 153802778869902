import { observable, action, makeObservable } from "mobx";
/* eslint-disable import/no-anonymous-default-export */
class layoutStore {
    isLoggedIn = window.localStorage.getItem("token") ? true : false;
    constructor() {
        makeObservable(this, {
            isLoggedIn: observable,
            setLoggedIn: action,
            logout: action,
        });
    }

    setLoggedIn = (token, usersData) => {
        this.isLoggedIn = true;
        window.localStorage.setItem("token", JSON.stringify(usersData));
    };
    logout = () => {
        this.isLoggedIn = false;
        window.localStorage.removeItem("token");
    }
}
export default new layoutStore();