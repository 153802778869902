import { useState } from "react";
import CustomSelect from "./customSelect.js";
import CustomInput from "../../components/form_elments/customInput";
const numericMapping = {
  cid: `numeric`,
  phone_number: `numeric`,
  rule_applied: `numeric`,
};
const getQueryValue = (operator, value) => {
  switch (operator) {
    case `contain`:
      value = `${value}`;
      break;
    case `start_with`:
      value = `^${value}`;
      break;
    case `end_with`:
      value = `.*${value}`;
      break;
    default:
      value = ``;
  }
  return value;
};
export default function CustomSearch({ currentPage, subscriber }) {
  currentPage = currentPage || "users";
  const [inputSearch, setSearchInput] = useState([
    JSON.parse(JSON.stringify(dbSearchObj[currentPage])),
  ]);
  const buildUpQuery = (genericQuery) => {
    if (genericQuery.query && currentPage === `users`) {
      genericQuery.query.type = `general`;
    }
    const filter = { isTotalNeeded: true, coll: collMap[currentPage] };
    if (genericQuery.query) {
      filter.filter = genericQuery.query;
    }
    if (genericQuery.usersQuery) {
      filter.usersFilter = genericQuery.usersQuery;
    }
    subscriber(filter, pageDb[currentPage]);
  };
  const submitQuery = () => {
    let genericQuery = { query: {}, usersQuery: {} };
    for (let j = 0; j < inputSearch.length; j += 1) {
      const inArr = inputSearch[j];
      let isValidCond = true;
      for (let i = 0; i < 3; i += 1) {
        const obj = inArr[i];
        if (obj.value === `disp` || !obj.value) {
          isValidCond = false;
          break;
        }
      }
      if (isValidCond) {
        const qVal = getQueryValue(inArr[1].value, inArr[2].value);
        if (!qVal) {
          break;
        }
        const searchField = inArr[0].value;
        let keyToSet = searchField,
          valToset;
        if (numericMapping[searchField]) {
          keyToSet = `$expr`;
          valToset = {
            $regexMatch: {
              input: { $toString: "$" + searchField },
              regex: qVal,
            },
          };
        } else {
          valToset = { $regex: qVal, $options: 'i' };
        }
        const qKey =
          usersAttr[searchField] && collMap[currentPage] !== `user_details`
            ? `usersQuery`
            : `query`;
        if (j === 0) {
          genericQuery[qKey][keyToSet] = valToset;
        } else {
          const cond = inputSearch[j - 1][3].value;
          const nq = {};
          nq[keyToSet] = valToset;
          if (genericQuery[qKey][cond]) {
            genericQuery[qKey][cond].push(nq);
          } else {
            const combine = {};
            combine[cond] = [genericQuery[qKey], nq];
            genericQuery[qKey] = combine;
          }
        }
      }
    }
    if (!Object.keys(genericQuery.query).length) {
      delete genericQuery.query;
    }
    if (!Object.keys(genericQuery.usersQuery).length) {
      delete genericQuery.usersQuery;
    }
    if (Object.keys(genericQuery).length) {
      buildUpQuery(genericQuery);
    }
  };
  const resetQuery = () => {
    const searchArr = JSON.parse(JSON.stringify(dbSearchObj[currentPage]));
    searchArr.forEach(elm => {
      elm.isReset = true;
    })
    setSearchInput([searchArr]);
  };
  const buildField = (elm) => {
    if (elm.type === "select") {
      return (
        <div className="sm:col-span-1">
          <CustomSelect
            item={elm}
            inputSearch={inputSearch}
            dbSearchObj={dbSearchObj}
            setSearchInput={setSearchInput}
            currentPage={currentPage}
          ></CustomSelect>
        </div>
      );
    }
    return (
      <div className="sm:col-span-1">
        <div className="mt-2">
          <CustomInput container={elm} keyName={"value"}></CustomInput>
        </div>
      </div>
    );
  };
  const getSearchField = () => {
    return (
      <ul
        role="list"
        className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4"
      >
        {inputSearch.map((iElm) =>
          iElm.map((elm) => (
            <li className="col-span-1 rounded-md shadow-sm">
              {buildField(elm)}
            </li>
          ))
        )}
      </ul>
    );
  };

  return (
    <form>
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12">
          {getSearchField()}
          <div className="mt-6 flex items-center">
            <button
              type="button"
              className="rounded bg-yellow-100 px-3 py-1 text-xs font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
              onClick={resetQuery}
            >
              Reset
            </button>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <button
              type="button"
              className="rounded bg-indigo-50 px-3  py-1 text-xs font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
              onClick={submitQuery}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

const collMap = {
  users: `user_details`,
  banks: `account_details`,
  accounts: `account_details`,
  transactions: `transaction_details`,
  grassrootlab: `grassrootlab_enquiries`,
  bank_details: `plaid_bank_list`,
  vendor_details: `vendor_details`,
  nws_by_vendor: `transaction_details`,
  nws_by_category: `plaid_cat_list`,
  nws_by_client: `transaction_details`,
  enable_features: `user_details`
};
const pageDb = {
  bank_details: `loadPageFromImages`,
  vendor_details: `loadPageFromImages`,
};
const usersAttr = {
  email: true,
  first_name: true,
  last_name: true,
};
const condOpt = {
  value: "disp",
  type: "select",
  map_key: "operator",
  options: [
    {
      text: "Select Operator",
      val: "disp",
    },
    {
      text: "Contains",
      val: "contain",
    },
    {
      text: "Start With",
      val: "start_with",
    },
    {
      text: "End With",
      val: "end_with",
    },
  ],
};
const searchField = {
  value: "",
  type: "text",
  map_key: "value",
};
const logicalOpt = {
  value: "disp",
  type: "select",
  map_key: "logical_operator",
  options: [
    {
      text: "Add more Query",
      val: "disp",
    },
    {
      text: "And",
      val: "$and",
    },
    {
      text: "Or",
      val: "$or",
    },
  ],
};
const dbSearchObj = {
  users: [
    {
      value: "disp",
      type: "select",
      map_key: "db_key",
      options: [
        {
          text: "Select search field",
          val: "disp",
        },
        {
          text: "CID",
          val: "cid",
        },
        {
          text: "Email",
          val: "email",
        },
        {
          text: "First name",
          val: "first_name",
        },
        {
          text: "Last Name",
          val: "last_name",
        },
      ],
    },
    condOpt,
    searchField,
    logicalOpt,
  ],
  banks: [
    {
      value: "disp",
      type: "select",
      map_key: "db_key",
      options: [
        {
          text: "Select search field",
          val: "disp",
        },
        {
          text: "CID",
          val: "cid",
        },
        {
          text: "Email",
          val: "email",
        },
        {
          text: "First name",
          val: "first_name",
        },
        {
          text: "Last Name",
          val: "last_name",
        },
        {
          text: "Ins Id",
          val: "item.institution_id",
        },
        {
          text: "Bank Name",
          val: "c_details.c_bank_name",
        },
      ],
    },
    condOpt,
    searchField,
    logicalOpt,
  ],
  accounts: [
    {
      value: "disp",
      type: "select",
      map_key: "db_key",
      options: [
        {
          text: "Select search field",
          val: "disp",
        },
        {
          text: "CID",
          val: "cid",
        },
        {
          text: "Email",
          val: "email",
        },
        {
          text: "First name",
          val: "first_name",
        },
        {
          text: "Last Name",
          val: "last_name",
        },
        {
          text: "Bank Name",
          val: "c_details.c_bank_name",
        },
      ],
    },
    condOpt,
    searchField,
    logicalOpt,
  ],
  transactions: [
    {
      value: "disp",
      type: "select",
      map_key: "db_key",
      options: [
        {
          text: "Select search field",
          val: "disp",
        },
        {
          text: "Email",
          val: "email",
        },
        {
          text: "First name",
          val: "first_name",
        },
        {
          text: "Last Name",
          val: "last_name",
        },
        {
          text: "Transactions Date",
          val: "date",
        },
        {
          text: "ITEM ID",
          val: "item_id",
        },
        {
          text: "Vendor Name Cleaned",
          val: "custom_field.vendor_name_cleaned",
        },
        {
          text: "Name",
          val: "name",
        },
        {
          text: "C Name",
          val: "custom_field.c_name",
        },
        {
          text: "Amount",
          val: "amount",
        },
        {
          text: "Category ID",
          val: "category_id",
        },
      ],
    },
    condOpt,
    searchField,
    logicalOpt,
  ],
  grassrootlab: [
    {
      value: "disp",
      type: "select",
      map_key: "db_key",
      options: [
        {
          text: "Select search field",
          val: "disp",
        },
        {
          text: "Email",
          val: "email",
        },
        {
          text: "Phone Number",
          val: "phone_number",
        },
        {
          text: "Full Name",
          val: "full_name",
        },
      ],
    },
    condOpt,
    searchField,
    logicalOpt,
  ],
  bank_details: [
    {
      value: "disp",
      type: "select",
      map_key: "db_key",
      options: [
        {
          text: "Select search field",
          val: "disp",
        },
        {
          text: "Bank Name",
          val: "name",
        },
        {
          text: "Institution Id",
          val: "ins_id",
        },
      ],
    },
    condOpt,
    searchField,
    logicalOpt,
  ],
  vendor_details: [
    {
      value: "disp",
      type: "select",
      map_key: "db_key",
      options: [
        {
          text: "Select search field",
          val: "disp",
        },
        {
          text: "Vendor Name Cleaned",
          val: "vendor_name_cleaned",
        },
        {
          text: "Updated On",
          val: "updated_on",
        },
        {
          text: "Created On",
          val: "created_on",
        },
        {
          text: "Rule Applied",
          val: "rule_applied",
        },
      ],
    },
    condOpt,
    searchField,
    logicalOpt,
  ],
  nws_by_vendor: [
    {
      value: "disp",
      type: "select",
      map_key: "db_key",
      options: [
        {
          text: "Select search field",
          val: "disp",
        },
        {
          text: "C Vendor Name",
          val: "c_vendor_name",
        },
        {
          text: "Plaid merchant",
          val: "name",
        },
      ],
    },
    condOpt,
    searchField,
    logicalOpt,
  ],
  nws_by_client: [
    {
      value: "disp",
      type: "select",
      map_key: "db_key",
      options: [
        {
          text: "Select search field",
          val: "disp",
        },
        {
          text: "C Vendor Name",
          val: "c_vendor_name",
        },
      ],
    },
    condOpt,
    searchField,
    logicalOpt,
  ],
  nws_by_category: [
    {
      value: "disp",
      type: "select",
      map_key: "db_key",
      options: [
        {
          text: "Select search field",
          val: "disp",
        },
        {
          text: "Category ID",
          val: "category_id",
        },
        {
          text: "Updated On",
          val: "updated_on",
        },
        {
          text: "Created On",
          val: "created_on",
        },
      ],
    },
    condOpt,
    searchField,
    logicalOpt,
  ],
  enable_features: [
    {
      value: "disp",
      type: "select",
      map_key: "db_key",
      options: [
        {
          text: "Select search field",
          val: "disp",
        },
        {
          text: "CID",
          val: "cid",
        },
        {
          text: "Email",
          val: "email",
        },
        {
          text: "First name",
          val: "first_name",
        },
        {
          text: "Last Name",
          val: "last_name",
        },
      ],
    },
    condOpt,
    searchField,
    logicalOpt,
  ],
};
