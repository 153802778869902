import React from "react";
import { CirclesWithBar } from "react-loader-spinner";
import { observer } from "mobx-react";
import http from "../../common/http";

const Loader = () => {
  if (!http.isLoader) {
    return <div></div>
  }
  return (
    <div>
      <div className="fixed  w-full min-h-screen z-50 bg-black opacity-30" />
      <div
        className="flex fixed w-full justify-center items-center h-screen"
        style={{ zIndex: 1000 }}
      >
        <CirclesWithBar
          height="100"
          width="100"
          color="#4fa94d"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          outerCircleColor=""
          innerCircleColor=""
          barColor=""
          ariaLabel="circles-with-bar-loading"
        />
      </div>
    </div>
  );
};

export default observer(Loader);
