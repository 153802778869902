import React, { useEffect, useState } from 'react';
import CentralizedData from "../../../stores/data";
import CustomInput from "../../../components/form_elments/customInput";
import { BuildCatData, getSubcategory, getRange, getLinkInput, getFilename } from './helper'
import '../DataTable.css';
import { saveAllChanges } from '../common'
import { ArchiveBoxXMarkIcon } from '@heroicons/react/20/solid';
import { PlusIcon as PlusOutline } from '@heroicons/react/20/solid';
import { MinusIcon as MinusSolid } from '@heroicons/react/20/solid';
import AddCategory from './add_category';
let imageObj = {};


const DataTable = ({ activeItem }) => {
    const [catData, setCatData] = useState([])
    useEffect(() => {
        const bData = CentralizedData.backend_data;
        BuildCatData(bData.catData)
        console.log(' bData.catData ', bData.catData)
        setCatData(bData.catData)
    })
    const loadTable = () => {
        setCatData([...catData])
    }
    const addCatLink = (index) => {
        const obj = catData[index];
        obj.link.push(`Enter link`);
        if (obj.link.length > obj.lsArr.length) {
            obj.lsArr.push(obj.lsArr.length);
        }
        loadTable()
    }
    const subCatLink = (index) => {
        const obj = catData[index];
        obj.link.pop();
        loadTable()
    }
    const addSubCatLink = (item, i) => {
        const linkArr = item.sub[i].link;
        linkArr.push(``);
        loadTable()
    }
    const subSubCatLink = (item, i) => {
        const linkArr = item.sub[i].link;
        linkArr.pop();
        loadTable()
    }
    const addSubCat = (index) => {
        const obj = catData[index];
        obj.sub.push({ cat: '', link: ['Enter link'] });
        if (obj.sub.length > obj.lsArr.length) {
            obj.lsArr.push(obj.lsArr.length);
        }
        loadTable()
    }
    const deleteCategory = (index) => {
        catData.splice(index, 1);
        loadTable()
    }
    const onFileChanged = (event, item, key) => {
        if (!item) {
            return;
        }
        item.icon = event.target.files;
        imageObj[key] = item;
        loadTable()
    }
    const deleteSubCategory = (index, innIndex) => {
        catData[index].sub.splice(innIndex, 1);
        catData[index].lsArr.pop()
        loadTable()
    }
    const onSaveClick = () => {
        saveAllChanges(activeItem, imageObj, catData, () => {
            imageObj = {}
            loadTable()
        })
    }
    return (
        <div className="tableTop">
            <div className="flex justify-end w-full">
                <AddCategory loadTable={loadTable} catData={catData}></AddCategory>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button style={{ height: '36px', width: '188px' }} className="flex justify-end items-center px-3 py-1 border border-blue-500 rounded hover:bg-gray-200" onClick={onSaveClick} >

                    Save all Changes
                </button>
            </div>

            <br />
            <table className="dataTable">
                <thead>
                    <tr>
                        <th className="px-4 py-2 text-xs">Categories</th>
                        <th className="px-4 py-2 text-xs">Category Link</th>
                        <th className="px-4 py-2 text-xs">Sub Category</th>
                        <th className="px-4 py-2 text-xs">Sub Category Link</th>
                        <th className="px-4 py-2 text-xs">Icon</th>
                        <th className="px-4 py-2 text-xs">Action</th>
                    </tr>
                </thead>

                {catData.map((item, j) => (
                    <tbody>
                        <tr className="h-12 bg-gray-200">
                            <td className="px-4 py-2 text-xs"><b>{item.cat} </b></td>
                            <td className="px-4 py-2 text-xs">
                                <div className="flex justify-between text-blue-500">
                                    <PlusOutline className="h-6 w-6 cursor-pointer hover:bg-green-100" onClick={addCatLink.bind(null, j)} />   <b>Category Link</b> <MinusSolid className="h-6 w-6 cursor-pointer hover:bg-green-100" onClick={subCatLink.bind(null, j)} />
                                </div>
                            </td>
                            <td>
                            </td>
                            <td>
                            </td>
                            <td className="px-4 py-2 text-xs">
                                <h6 >{getFilename(item)}</h6>
                                <input type="file" name="file" id="file" onChange={(event) => {
                                    onFileChanged(event, item, item.cat)
                                }} />
                            </td>
                            <td className="px-4 py-2 text-xs">
                                <div className="row">
                                    <button className=" items-center px-4 py-1 border border-green-500 rounded hover:bg-gray-200" onClick={addSubCat.bind(null, j)}>

                                        Add Sub Categories
                                    </button>&nbsp;&nbsp;&nbsp;
                                    <button className=" items-center px-4 py-1 border border-red-500 rounded hover:bg-gray-200" onClick={deleteCategory.bind(null, j)}>

                                        Delete Category
                                    </button>
                                </div>


                            </td>
                        </tr>
                        {item.lsArr.map((i) => (
                            <tr className="h-12">
                                <td className="px-4 py-2 text-xs"></td>
                                <td className="px-4 py-2 text-xs">
                                    {/* <CustomInput container={item.link} keyName={i}></CustomInput> */}
                                    {getLinkInput(item.link, i)}
                                </td>
                                <td className="px-4 py-2 text-xs">
                                    {getSubcategory(item.sub, i)}

                                </td>
                                <td className="px-4 py-2 text-xs">
                                    {(item.sub[i] && item.sub[i].link && item.sub[i].link.length) ? (<div>
                                        <div className="flex justify-between text-blue-500">
                                            <PlusOutline className="h-6 w-6 cursor-pointer hover:bg-green-100" onClick={addSubCatLink.bind(null, item, i)} />   <b> Link</b> <MinusSolid className="h-6 w-6 cursor-pointer hover:bg-green-100" onClick={subSubCatLink.bind(null, item, i)} />
                                        </div>
                                        {getRange(item.sub[i].link.length).map(elm => (
                                            <div className="inputBoxTop">
                                                <CustomInput container={item.sub[i].link} keyName={elm} placeholder="Enter subcategory link"></CustomInput>

                                            </div>
                                        ))}
                                    </div>) : (<div></div>)}

                                </td>
                                <td className="px-4 py-2 text-xs">{(item.sub[i] && item.sub[i].link && item.sub[i].link.length) ? (<div><input type="file" name="file" id="file" onChange={(event) => {
                                    onFileChanged(event, item.sub[i], item.sub[i].cat)
                                }} />{getFilename(item.sub[i])}</div>) : ''}</td>

                                <td className="deleteBtnCentre px-4 py-2 text-xs">
                                    {(item.sub[i] && item.sub[i].link && item.sub[i].link.length) ? <button className="flex justify-center  items-center px-3 py-1 border border-red-500 rounded hover:bg-gray-200" onClick={deleteSubCategory.bind(null, j, i)}>
                                        <ArchiveBoxXMarkIcon className="h-4 w-4 mr-1" />
                                        Delete
                                    </button> : ''}

                                </td>
                            </tr>
                        ))}
                    </tbody>

                ))}

            </table>
        </div>
    );
}

export default DataTable;
