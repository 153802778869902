import { useState, useEffect } from "react";
import CentralizedData from "../../stores/data";
import ServerTable from "../../components/atoms/serverTable";
import { useLocation } from "react-router-dom";
import http from "../../common/http";
import CustomSearch from "../search";
import { columnFormattar, formatter } from "./helper";
let initialFilter = {
  isTotalNeeded: true,
  coll: "plaid_bank_list",
  filter: {},
};
let apiURL = "loadPageFromImages";
let imageObj = {};
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable no-script-url */

export default function Users() {
  const location = useLocation();
  const bData = CentralizedData.backend_data;
  const [column, setColumn] = useState(bData.routeJson.bank_details.column);
  const [filter, setFilter] = useState(initialFilter);
  useEffect(() => {
    if (!column.length) {
      const setData = (err) => {
        // TODO handle error
        setColumn(bData.routeJson.users.column);
      };
      http.getInitialData(setData);
    }
    return () => {
      imageObj = {};
    };
  });
  if (!column.length) {
    return <div></div>;
  }
  const subscriber = (searchFilter, api) => {
    apiURL = api;
    setFilter(searchFilter);
  };
  const currentPage = location.state && location.state.currentPage;

  const onFileSelect = (item, event) => {
    console.log("item", item, event);
    const bKey = item.b_key;
    if (!bKey) {
      return;
    }
    imageObj[bKey] = event.target.files;
  };
  columnFormattar(column, onFileSelect);
  const onSaveClick = () => {
    const formData = new FormData();
    for (let key in imageObj) {
      for (const file of imageObj[key]) {
        formData.append(key, file, file.name);
      }
    }
    http.makeCall("/saveBankImages", "post", formData, (err, res) => {
      if (err) {
        http.errorMsg = err.message || "Something went wrong";
        return console.log(`error = `, err);
      }
      http.notification = "Updated successfully";
      imageObj = {};
    });
  };
  return (
    <div>
      <CustomSearch
        currentPage={currentPage}
        subscriber={subscriber}
      ></CustomSearch>
      <div className="sm:col-span-1">
        <button
          onClick={onSaveClick}
          type="button"
          className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Save All Changes
        </button>
      </div>
      <ServerTable
        filters={filter}
        formatter={formatter}
        columns={column}
        apiURL={apiURL}
      ></ServerTable>
    </div>
  );
}
