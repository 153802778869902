import CustomSelect from "../../components/form_elments/select";
import { nwsList } from "../../stores/data.js"
import http from "../../common/http";

/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable no-script-url */
const keyChange = {
    v_def_cat: "def_cat",
    v_def_sub_cat: "def_sub_cat",
    v_def_nws: "def_nws"
}

export const columnFormattar = (column, catData, catMap, onSelectionChange, categorySelection) => {

    column.forEach(elm => {
        if (elm.selector === 'def_nws') {
            console.log('selector elm => ', elm)
            elm.cell = row => {
                // console.log('row ', row)
                return (<CustomSelect itemList={nwsList} onSelectionChange={onSelectionChange} container={row} cKey='def_nws' ></CustomSelect>)
            }
        }
        if (elm.selector === 'def_cat') {
            elm.cell = row => {
                return (<CustomSelect itemList={catData} onSelectionChange={onSelectionChange} container={row} cKey='def_cat' label='cat' value='cat'></CustomSelect>)
            }
        }
        if (elm.selector === 'def_sub_cat') {
            elm.cell = row => {
                const itemList = (catMap[row.def_cat] && catMap[row.def_cat].sub) || []

                return (<CustomSelect itemList={itemList} catMap={catMap} onSelectionChange={onSelectionChange} container={row} cKey='def_sub_cat' label='cat' value='cat'></CustomSelect>)
            }
        }
    });
};

export const formatter = (pData, setTableLoader) => {
    const catObj = {};
    pData.forEach((elm) => {
        for (let i = 0; i < elm.category.length; i += 1) {
            const key = `cat_${i + 1}`;
            elm[key] = elm.category[i];
        }
        catObj[elm.category_id] = true;
    });

    http.makeCall(
        "/getVendorCatList",
        "post",
        Object.keys(catObj),
        (err, res) => {
            if (err) {
                http.errorMsg = err.message || 'Something went wrong';
                setTableLoader(false);
                return console.log(`error = `, err);
            }
            const catMap = {};
            res.data.pList.forEach(element => {
                catMap[element.category_id] = element;
            });
            setTableLoader(false);
            pData.forEach(element => {
                const vObj = catMap[element.category_id];
                if (vObj && vObj.vendor) {
                    for (const vKey in vObj.vendor) {
                        const vInnerObj = vObj.vendor[vKey];
                        const vName = element.c_vendor_name || (element.custom_field && element.custom_field.vendor_name_cleaned) || ``
                        const keyToMatch = vName.substring(0, 50).replace(/ /g, ``);
                        if (keyToMatch === vKey) {
                            for (const innerKey in vInnerObj) {
                                const assignKey = keyChange[innerKey] || innerKey;
                                element[assignKey] = vInnerObj[innerKey];
                            }
                        }
                    }
                }
            });
        }
    );

    return pData;
};

export const saveAllChanges = (defaultCatObj) => {
    const defCatLen = Object.keys(defaultCatObj).length;
    if (!defCatLen) {
        http.errorMsg = 'Please make changes and then click on save button';
        return
    }
    const objToSend = {};
    if (defCatLen) {
        objToSend.defaultCatObj = defaultCatObj;
    }
    http.makeCall(
        "/saveAllCATNWSVendor",
        "post",
        objToSend,
        (err, res) => {
            if (err) {
                http.errorMsg = err.message || 'Something went wrong';
                return;
            }
            http.notification = 'Updated successfully'
        });
}



