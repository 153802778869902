import React, { useState } from 'react';
import CustomInput from "../../../components/form_elments/customInput";
const container = {}
const AddCategory = ({ manualAccountType, setmanualAccountType }) => {
    const [showCatInput, setShowCatInput] = useState(false)
    const showForm = () => {
        if (showCatInput && container.value) {
            manualAccountType.push({ type: container.value, sub: [], icon: "" });
            container.value = ''
            setmanualAccountType([...manualAccountType])
        }
        setShowCatInput(!showCatInput)
    }
    const getInput = () => {
        if (showCatInput) {
            return <CustomInput container={container} keyName="value" placeholder="Enter New Category name"></CustomInput>
        }
        return ''
    }
    return (
        <div className="flex justify-end w-full">
            {getInput()}
            &nbsp;&nbsp;&nbsp;
            <button className="flex justify-end items-center px-3 py-1 border border-red-500 rounded hover:bg-gray-200" onClick={showForm}>

                {showCatInput ? 'Save' : 'Add'} new Type
            </button>
        </div>
    );
}

export default AddCategory;
