import React from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { observer } from "mobx-react";
import http from "../../common/http";
const contextClass = {
  success: "bg-blue-600",
  error: "bg-red-600",
  info: "bg-gray-600",
  warning: "bg-orange-400",
  default: "bg-indigo-600",
  dark: "bg-white-600 font-gray-300",
};
function Toast() {
  if (http.errorMsg) {
    toast(http.errorMsg, {
      onClose: () => {
        http.errorMsg = ''
      }
    });
  }
  if (http.notification) {
    toast(http.notification, {
      onClose: () => {
        http.notification = ''
      }
    });
  }
  const dClass = http.errorMsg ? contextClass.error : contextClass.success;
  return (
    <div>
      <ToastContainer
        toastClassName={({ type }) =>
          dClass +
          " relative flex p-1 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer"
        }
        bodyClassName={() => "text-sm font-white font-med block p-3"}
        autoClose={5000}
      />
    </div>
  );
}

export default observer(Toast);
