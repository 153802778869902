import { useState, useEffect } from "react";
import CentralizedData from "../../stores/data";
import ServerTable from "../../components/atoms/serverTable";
import { useLocation } from 'react-router-dom';
import http from "../../common/http";
import CustomSearch from "../search";
import { columnFormattar } from './helper'
import ConfirmDeleteModal from './confirm'
let initialFilter = {
  isTotalNeeded: true,
  coll: "user_details",
  filter: { type: "general" },
};
let apiURL = 'loadMorePageData';
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable no-script-url */
let deletedRow;
export default function Users() {
  const location = useLocation();
  const bData = CentralizedData.backend_data;
  const [column, setColumn] = useState(bData.routeJson.users.column);
  const [filter, setFilter] = useState(initialFilter);
  const [isDeleteModal, setDeleteModal] = useState(false);
  useEffect(() => {
    if (!column.length) {
      const setData = (err) => {
        // TODO handle error
        setColumn(bData.routeJson.users.column);
      };
      http.getInitialData(setData);
    }
    return () => {
      // deletedRow = null
    }
  });
  if (!column.length) {
    return <div></div>;
  }
  const onConfirm = () => {
    http.makeCall(`/removeUsers`, `post`, { cid: deletedRow.cid }, (err, res) => {
      deletedRow = null
      if (err) {
        http.errorMsg = err.message || "Something went wrong";
        return console.log(`error = `, err);
      }
      http.notification = 'Deleted   successfully';
      setDeleteModal(false)

    });
  }
  const onCancel = () => {
    deletedRow = null
    setDeleteModal(false)
  }
  if (isDeleteModal) {
    return (<ConfirmDeleteModal onConfirm={onConfirm} onCancel={onCancel}></ConfirmDeleteModal>)
  }
  const onDeleteClick = (row, index) => {
    deletedRow = row
    return setDeleteModal(true)

  }
  columnFormattar(column, onDeleteClick)
  const subscriber = (searchFilter, api) => {
    apiURL = api;
    setFilter(searchFilter);
  };
  const currentPage = location.state && location.state.currentPage;
  return (
    <div>
      <CustomSearch currentPage={currentPage} subscriber={subscriber}></CustomSearch>
      <ServerTable filters={filter} columns={column} apiURL={apiURL}></ServerTable>
    </div>
  );
}
