import CheckBox from "../../components/form_elments/checkbox";
import CustomInput from "../../components/form_elments/customInput";
import http from "../../common/http";
import { ArchiveBoxXMarkIcon } from "@heroicons/react/20/solid";
const cellRow = {
  display: "contents",
};
/* eslint-disable no-restricted-globals */
export const GetVendorCount = (vendorCountObj, cb) => {
  http.makeCall("/get_vendor_count", "get", {}, (err, res) => {
    if (err) {
      return console.log("remove_vendor_rule error");
    }
    vendorCountObj.total_affected_transactions =
      res.data.total_affected_transactions;
    vendorCountObj.vendor_count = res.data.vendor_count;
    cb();
  });
};
export const BuildCustomCell = (
  column,
  savedObj,
  deletedVendorKey,
  onFileChanged,
  onIconDelete
) => {
  const selectChangedObj = (vendorInfo, key) => {
    if (!savedObj[vendorInfo.vendor_key]) {
      savedObj[vendorInfo.vendor_key] = {};
    }
    savedObj[vendorInfo.vendor_key].c_vendor_name = vendorInfo.c_vendor_name;
    savedObj[vendorInfo.vendor_key][key] = vendorInfo[key];
  };
  const selectRemoveChangedObj = (vendorObj, index) => {
    if (vendorObj.bulk_remove) {
      deletedVendorKey[vendorObj.vendor_key] = {
        vendor_name_cleaned: vendorObj.vendor_name_cleaned,
        index,
      };
    } else {
      delete deletedVendorKey[vendorObj.vendor_key];
    }
  };

  column.forEach((elm) => {
    if (elm.map_key === "is_correct") {
      elm.cell = (row, index, column, id) => {
        return (
          <CheckBox
            changeListner={selectChangedObj.bind(null, row, column.selector)}
          ></CheckBox>
        );
      };
    }
    if (elm.map_key === "bulk_remove") {
      elm.cell = (row, index, column, id) => {
        return (
          <CheckBox
            changeListner={selectRemoveChangedObj.bind(null, row, index)}
          ></CheckBox>
        );
      };
    }
    if (elm.map_key === "manual_entry") {
      elm.cell = (row, index, column, id) => {
        return (
          <CustomInput
            container={row}
            keyName={column.selector}
            onBlur={selectChangedObj}
          ></CustomInput>
        );
      };
    }
    if (elm.map_key === "gv_name") {
      elm.cell = (row, index, column, id) => {
        return (
          <CustomInput
            container={row}
            keyName={column.selector}
            onBlur={selectChangedObj}
          ></CustomInput>
        );
      };
    }
    if (elm.map_key === "upload_file") {
      elm.cell = (row, index, column, id) => {
        return (
          <input
            type="file"
            accept="image/*"
            onChange={(event) => {
              onFileChanged(event, row);
            }}
          />
        );
      };
    }
    if (elm.map_key === "o_name") {
      elm.cell = (row, index, column, id) => {
        return (
          <div style={cellRow} id={row._id}>
            {row.o_name}
            &nbsp;&nbsp;&nbsp;&nbsp;
            {row.o_name ? (
              <button
                type="button"
                className="rounded-full redCol bg-indigo-600 p-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={onIconDelete.bind(null, row)}
              >
                <ArchiveBoxXMarkIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            ) : (
              ""
            )}
          </div>
        );
      };
    }
    if (elm.map_key === "delete") {
      elm.cell = (row, index, column, id) => {
        return (
          <button
            type="button"
            className="rounded-full redCol bg-indigo-600 p-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <ArchiveBoxXMarkIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        );
      };
    }
  });
};
