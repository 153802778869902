import { useEffect, useState } from "react";
import CentralizedData from "../../stores/data";
import ServerTable from "../../components/atoms/serverTable";
import CustomSearch from "../search";
import { useLocation } from 'react-router-dom';
import http from '../../common/http';
const initialFilter = {
  isTotalNeeded: true,
  coll: "transaction_details",
  filter: {},
};
let apiURL = 'loadMorePageData';
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable no-script-url */

export default function Transaction() {
  const location = useLocation();
  const bData = CentralizedData.backend_data;
  const currentPage = location.state && location.state.currentPage;
  const cRef = bData.routeJson[currentPage] || {}
  const column = cRef.column || [];
  const [filter, setFilter] = useState(initialFilter);
  useEffect(() => { }, []);
  if (!column.length) {
    return <div></div>;
  }
  const subscriber = (searchFilter, api) => {
    apiURL = api;
    setFilter(searchFilter);
  };
  return (
    <div>
      <CustomSearch currentPage={currentPage} subscriber={subscriber}></CustomSearch>
      <ServerTable filters={filter} formatter={formatter} columns={column} apiURL={apiURL}></ServerTable>
    </div>
  );
}


const getCategory = (catList, itemIDList, elmToIterate, setTableLoader) => {
  http.makeCall("/getTransCatList",
    "post",
    { catList, itemIDList },
    (err, backendData) => {
      if (err) {
        setTableLoader(false);
        // TODO handle error
        return console.log("get trans cat list");
      }
      const res = backendData.data;
      const catMap = {}, clientCatMap = {};
      res.pList.forEach(element => {
        catMap[element.category_id] = element;
      });
      res.usersCat.forEach(element => {
        if (element.plaid_identifier) {
          element.plaid_identifier.forEach(itemObj => {
            clientCatMap[itemObj.item_id] = element;
          });
        }
      });
      // pList: plaidCatList, usersCat
      elmToIterate.forEach(actTrans => {
        const cObj = actTrans.custom_field || {};
        actTrans.posted_date = cObj.posted_date;
        const obj = {
          c_category: cObj.category,
          c_sub_category: cObj.sub_category,
          c_nws: cObj.nws,
          cl_cat: cObj.category,
          cl_sub_cat: cObj.sub_category,
          cl_nws: cObj.nws
        }
        let uCatObj;
        if (clientCatMap[actTrans.item_id]) {
          uCatObj = clientCatMap[actTrans.item_id].category;
          actTrans.cid = clientCatMap[actTrans.item_id].cid;
        }
        if (uCatObj) {
          // tslint:disable-next-line: max-line-length
          const keyToMatch = actTrans.category_id + "_" + ((actTrans.c_vendor_name && actTrans.c_vendor_name.substring(0, 50).replace(/ /g, ``)) || ``);
          // tslint:disable-next-line:forin
          for (const uKey in uCatObj) {
            const uInnerObj = uCatObj[uKey];
            if (keyToMatch === uKey) {
              obj.c_category = obj.c_category || uInnerObj.cl_cat;
              obj.c_sub_category = obj.c_sub_category || uInnerObj.cl_sub_cat;
              obj.c_nws = obj.c_nws || uInnerObj.cl_nws;
              // tslint:disable-next-line:forin
              for (let key in uCatObj) {
                obj[key] = uCatObj[key];
              }
            }
          }
        }
        const vObj = catMap[actTrans.category_id];
        if (vObj.vendor) {
          // tslint:disable-next-line:forin
          for (const vKey in vObj.vendor) {
            const vInnerObj = vObj.vendor[vKey];
            // tslint:disable-next-line: max-line-length
            const keyToMatch = actTrans.c_vendor_name && actTrans.c_vendor_name.substring(0, 50).replace(/ /g, ``);
            if (keyToMatch === vKey) {
              obj.c_category = obj.c_category || vInnerObj.v_def_cat;
              obj.c_sub_category = obj.c_sub_category || vInnerObj.v_def_sub_cat;
              obj.c_nws = obj.c_nws || vInnerObj.v_def_nws;
              obj.def_cat = vInnerObj.v_def_cat;
              obj.def_sub_cat = vInnerObj.v_def_sub_cat;
              obj.def_nws = vInnerObj.v_def_nws;
            }
          }
        } else {
          obj.c_category = obj.c_category || vObj.def_cat;
          obj.c_sub_category = obj.c_sub_category || vObj.def_sub_cat;
          obj.c_nws = obj.c_nws || vObj.def_nws;
          obj.def_cat = vObj.def_cat;
          obj.def_sub_cat = vObj.def_sub_cat;
          obj.def_nws = vObj.def_nws;
        }
        // tslint:disable-next-line:forin
        for (let key in obj) {
          actTrans[key] = obj[key];
        }
      });
      setTableLoader(false);
    }
  );

}
const formatter = (pData, setTableLoader) => {
  const arr = [];
  const catList = {}, itemIDList = {};
  pData.forEach(actTransactions => {
    let locStr = ``;
    // tslint:disable-next-line:no-shadowed-variable
    for (const key in actTransactions.location) {
      if (actTransactions.location[key]) {
        locStr += `${key} - ${actTransactions.location[key]}, `;
      }
    }
    let paymentMeta = ``;
    
    // tslint:disable-next-line:no-shadowed-variable
    for (const key in actTransactions.payment_meta) {
      if (actTransactions.payment_meta[key]) {
        paymentMeta += `${key} - ${actTransactions.payment_meta[key]}, `;
      }
    }
    catList[actTransactions.category_id] = true;
    itemIDList[actTransactions.item_id] = true;
    actTransactions.location_details = locStr;
    actTransactions.payment_meta = paymentMeta;
    if (actTransactions.custom_field) {
      actTransactions.vendor_name_cleaned = actTransactions.custom_field.vendor_name_cleaned;
      actTransactions.c_name = actTransactions.custom_field.c_name;
      actTransactions.rule_applied = actTransactions.custom_field.rule_applied;
      actTransactions.rules = actTransactions.custom_field.rules;
    }
    for (let i = 0; i < actTransactions.category.length; i += 1) {
      const key = `cat_${i + 1}`;
      actTransactions[key] = actTransactions.category[i];
    }
    if (actTransactions.split_details) {
      actTransactions.split_details.forEach(splElm => {
        const copyTrans = JSON.parse(JSON.stringify(actTransactions));
        copyTrans.amount = splElm.amount;
        copyTrans.custom_field = copyTrans.custom_field || actTransactions.custom_field;
        copyTrans.isSplit = `yes`;
        arr.push(copyTrans);
      });
    }
    arr.push(actTransactions)
  });
  getCategory(Object.keys(catList), Object.keys(itemIDList), arr, setTableLoader)
  return arr;
  // this.getCategory(Object.keys(catList), Object.keys(itemIDList), arr);
};
