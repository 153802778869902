import { useState, useEffect } from "react";
import CentralizedData from "../../stores/data";
import ServerTable from "../../components/atoms/serverTable";
import { useLocation } from "react-router-dom";
import http from "../../common/http";
import CustomSearch from "../search";
import CheckBox from "../../components/form_elments/checkbox";
let initialFilter = {
  isTotalNeeded: true,
  coll: "user_details",
  filter: { type: "general" },
};
let apiURL = "loadMorePageData";
let selectedRowObj = {};
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable no-script-url */
export default function Enable_features() {
  const location = useLocation();
  const bData = CentralizedData.backend_data;
  const [column, setColumn] = useState(bData.routeJson.enable_features.column);
  const [filter, setFilter] = useState(initialFilter);
  useEffect(() => {
    if (!column.length) {
      const setData = (err) => {
        // TODO handle error
        setColumn(bData.routeJson.enable_features.column);
      };
      http.getInitialData(setData);
    }
  });
  if (!column.length) {
    return <div></div>;
  }

  const onEnablingFeature = (row) => {
    selectedRowObj[row.cid] = row
    row.beta_enabled = !row.beta_enabled


  };
  column.forEach((elm) => {
    if (elm.selector === "checkbox") {
      elm.cell = (row) => {
        return (
          <CheckBox status={row.beta_enabled} changeListner={onEnablingFeature.bind(null, row)}></CheckBox>
        );
      };
    }
  });
  const subscriber = (searchFilter, api) => {
    apiURL = api;
    setFilter(searchFilter);
  };
  const onSaveClick = () => {
    const cidList = Object.keys(selectedRowObj);
    if (!cidList.length) {
      http.errorMsg = 'Please make changes and then click on save button';
      return
    }
    const objToSend = [];
    cidList.forEach(cid => {
      objToSend.push({ cid, beta_enabled: selectedRowObj[cid].beta_enabled })
    })
    http.makeCall(
      "/set_features",
      "post",
      objToSend,
      (err, res) => {
        if (err) {
          http.errorMsg = err.message || 'Something went wrong';
          return;
        }
        http.notification = 'Updated successfully'
      });
  }
  const currentPage = location.state && location.state.currentPage;
  return (
    <div>
      <CustomSearch
        currentPage={currentPage}
        subscriber={subscriber}
      ></CustomSearch>
      <div className="sm:col-span-1 right-align">
        <button onClick={onSaveClick}
          type="button"
          className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 "
        >
          Save All Changes
        </button>
      </div>
      <ServerTable
        filters={filter}
        columns={column}
        apiURL={apiURL}
      ></ServerTable>
    </div>
  );
}
