/* eslint-disable jsx-a11y/no-redundant-roles */
import { useState } from "react";
import CustomSelect from "../../components/form_elments/select";
import { nwsList } from "../../stores/data.js"
import http from "../../common/http";
const containerStyle = {
    backgroundColor: '#e2e9f9',
    height: '60px',
    borderRadius: '11px',
};
const allignMent = {
    marginTop: '11px',
    marginLeft: '31px'
}
const buttonAllignment = {
    marginTop: '11px'
}
export default function BulkSelectUpdate({ container, updatedCatObj, catMap, catData, callingFn }) {
    const onSaveClick = () => {
        const obj = JSON.parse(JSON.stringify(updatedCatObj));
        const keyList = Object.keys(obj);
        if (!keyList.length) {
            http.errorMsg = 'Please make some update and then click on save button'
            return
        }
        const objToSend = {};
        keyList.forEach(elm => {
            delete obj[elm]._id
            delete obj[elm].def_cat_fn
            delete obj[elm].def_sub_cat_fn
            delete obj[elm].def_nws_fn
        })
        objToSend.updatedCatObj = obj
        http.makeCall(
            "/saveAllNws",
            "post",
            objToSend,
            (err, res) => {
                if (err) {
                    http.errorMsg = err.message || 'Something went wrong'
                    return console.log(`error = `, err);
                }
                http.notification = 'Updated successfully'
            }
        );
    }
    return (
        <form style={containerStyle}>
            <div className="space-y-12" style={allignMent}>
                <div className="border-b border-gray-900/10 pb-12">
                    <ul
                        role="list"
                        className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4"
                    >
                        <li className="col-span-1 rounded-md shadow-sm">
                            <div className="sm:col-span-1">
                                <CustomSelect itemList={nwsList} onSelectionChange={callingFn} container={container} cKey='def_nws' ></CustomSelect>
                            </div>
                        </li>
                        <li className="col-span-1 rounded-md shadow-sm">
                            <div className="sm:col-span-1">
                                <CustomSelect itemList={catData} onSelectionChange={callingFn} container={container} label='cat' value='cat' cKey='def_cat' ></CustomSelect>
                            </div>
                        </li>
                        <li className="col-span-1 rounded-md shadow-sm">
                            <div className="sm:col-span-1">
                                <CustomSelect itemList={[]} catMap={catMap} onSelectionChange={callingFn} container={container} label='cat' value='cat' cKey='def_sub_cat' ></CustomSelect>
                            </div>
                        </li>
                        <li className="col-span-1 rounded-md shadow-sm">
                            <div className="sm:col-span-1">
                                <button onClick={onSaveClick}
                                    type="button"
                                    style={buttonAllignment}
                                    className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                >
                                    Save All Changes
                                </button>
                            </div>
                        </li>


                    </ul>

                </div>
            </div>
        </form>
    );
}