import { useState, useEffect } from "react";
import CustomInput from "../../components/form_elments/customInput";
import http from "../../common/http";
import useForceUpdate from "use-force-update";
import { ArchiveBoxXMarkIcon } from "@heroicons/react/20/solid";


let vCount = 0;
export default function VendorCleanup() {
  const [ruleLists, setRuleList] = useState([]);
  const forceUpdate = useForceUpdate();
  useEffect(() => {
    if (!ruleLists.length) {
      http.makeCall("/getVendorRule", "post", {}, (err, res) => {
        if (err) {
          return console.error(err);
        }
        const ruleList =
          (res.data && res.data.listData && res.data.listData.data) || [];
        vCount = 0;
        ruleList.forEach((elm) => {
          elm.list.forEach((rElm) => {
            vCount += rElm.affected_transactions || 0;
          });
        });
        setRuleList(ruleList);
      });
    }
  });
  const addRule = (item) => {
    item.list.push({});
    forceUpdate();
  };
  const deleteRule = (item, index) => {
    item.splice(index, 1);
    forceUpdate();
  };
  const saveAllChanges = () => {
    http.makeCall("/saveVendorRule", "post", ruleLists, (err, res) => {
      if (err) {
        return console.error(err);
      }
      console.log("success on saving");
    }, true);
  };
  const applyVendorRule = () => {
    http.makeCall("/apply_vendor_rule", "get", {}, (err, res) => {
      if (err) {
        return console.error(err);
      }
      console.log("success on saving");
    });
  };
  const getTds = (item, inItem, index) => {
    if (item.rule === "show_in_between") {
      return (
        <tr className="divide-x divide-gray-200">
          <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0"></td>
          <td className="whitespace-nowrap lesspad text-sm text-gray-500">
            {item.f_text}
          </td>
          <td className="whitespace-nowrap lesspad text-sm text-gray-500">
            <CustomInput
              container={inItem}
              keyName={"name_contain"}
            ></CustomInput>
          </td>
          <td className="whitespace-nowrap lesspad text-sm text-gray-500">
            {item.s_text}
          </td>
          <td className="whitespace-nowrap lesspad text-sm text-gray-500">
            <CustomInput container={inItem} keyName={"r_val_b1"}></CustomInput>
          </td>
          <td className="whitespace-nowrap lesspad text-sm text-gray-500">
            {item.t_text}
          </td>
          <td className="whitespace-nowrap lesspad text-sm text-gray-500">
            <CustomInput container={inItem} keyName={"r_val_b2"}></CustomInput>
          </td>
          <td className="whitespace-nowrap lesspad text-sm text-gray-500">
            <button
              type="button"
              onClick={deleteRule.bind(null, item.list, index)}
              className="rounded-full bg-indigo-600 p-1 redCol text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <ArchiveBoxXMarkIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </td>
          <td className="whitespace-nowrap lesspad text-sm text-gray-500">
            {inItem.affected_transactions}
          </td>
        </tr>
      );
    }
    return (
      <tr className="divide-x divide-gray-200">
        <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0"></td>
        <td className="whitespace-nowrap lesspad text-sm text-gray-500">
          {item.f_text}
        </td>
        <td className="whitespace-nowrap lesspad text-sm text-gray-500">
          <CustomInput
            container={inItem}
            keyName={"name_contain"}
            removeSpChar={true}
          ></CustomInput>
        </td>
        <td className="whitespace-nowrap lesspad text-sm text-gray-500">
          {item.s_text}
        </td>
        <td className="whitespace-nowrap lesspad text-sm text-gray-500">
          <CustomInput
            container={inItem}
            keyName={"r_val"}
            removeSpChar={true}
          ></CustomInput>
        </td>
        <td className="">
          <button
            type="button"
            onClick={deleteRule.bind(null, item.list, index)}
            className="rounded-full redCol bg-indigo-600 p-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <ArchiveBoxXMarkIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </td>
        <td className="whitespace-nowrap lesspad text-sm text-gray-500">
          {inItem.affected_transactions}
        </td>
      </tr>
    );
  };
  const getBody = () => {
    return (
      <>
        {ruleLists.map((item) => (
          <tbody className="divide-y divide-gray-200 bg-white">
            <tr className="divide-x divide-gray-200">
              <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">
                <b>{item.disp_text} </b>
              </td>
              <td className="whitespace-nowrap p-4 text-sm text-gray-500">
                <button
                  type="button"
                  onClick={addRule.bind(null, item)}
                  className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Add Rule
                </button>
              </td>
            </tr>
            {item.list.map((inItem, index) => getTds(item, inItem, index))}
          </tbody>
        ))}
      </>
    );
  };
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Rule count {vCount}
          </button>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            onClick={applyVendorRule}
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Apply Vendor Rule
          </button>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            onClick={saveAllChanges}
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Save All Changes
          </button>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr className="divide-x divide-gray-200">
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Rule Title
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Rule List
                  </th>
                </tr>
              </thead>
              {getBody()}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
