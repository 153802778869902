import CustomSelect from "../../components/form_elments/select";
import CheckBox from "../../components/form_elments/checkbox";
import { nwsList } from "../../stores/data.js"


/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable no-script-url */
export const formatter = (pData, setTableLoader) => {
    // pData.forEach((elm) => {
    //     elm.institution_id = elm.item.institution_id;
    //     elm.bank_name = elm.c_details && elm.c_details.c_bank_name;
    //     elm.no_of_accounts = elm.accounts.length;
    //     elm.item_id = elm.item.item_id;
    // });
    setTableLoader(false);
    return pData;
};
export const columnFormattar = (column, catData, catMap, onSelectionChange, categorySelection) => {

    column.forEach(elm => {

        if (elm.selector === 'def_nws') {
            console.log('selector elm => ', elm)
            elm.cell = row => {
                // console.log('row ', row)
                return (<CustomSelect itemList={nwsList} onSelectionChange={onSelectionChange} container={row} cKey='def_nws' ></CustomSelect>)
            }
        }
        if (elm.selector === 'def_cat') {
            elm.cell = row => {
                return (<CustomSelect itemList={catData} onSelectionChange={onSelectionChange} container={row} cKey='def_cat' label='cat' value='cat'></CustomSelect>)
            }
        }
        if (elm.selector === 'def_sub_cat') {
            elm.cell = row => {
                const itemList = (catMap[row.def_cat] && catMap[row.def_cat].sub) || []

                return (<CustomSelect itemList={itemList} catMap={catMap} onSelectionChange={onSelectionChange} container={row} cKey='def_sub_cat' label='cat' value='cat'></CustomSelect>)
            }
        }
        if (elm.selector === 'checkbox') {
            elm.cell = row => {

                return (<CheckBox
                    changeListner={categorySelection.bind(null, row, column.selector)}
                ></CheckBox>)
            }
        }
    });
};

export const updateBulkRow = (container, updatedCatObj, cKey) => {
    const allValue = Object.values(updatedCatObj);
    allValue.forEach(elm => {
        elm[cKey] = container[cKey];
        if (elm[cKey + '_fn']) {
            elm[cKey + '_fn']()
        }
        if (cKey === 'def_cat') {
            elm.def_sub_cat = '';
            if (elm.def_sub_cat_fn) {
                elm.def_sub_cat_fn()
            }
        }
    })
}