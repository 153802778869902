import { useState, useEffect } from 'react';
import CentralizedData from '../../stores/data';
import ServerTable from '../../components/atoms/serverTable';
import { useLocation } from 'react-router-dom';
import http from '../../common/http';
import CustomSearch from '../search';
import CheckBox from '../../components/form_elments/checkbox';
import {
  storage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from '../../common/firebase';
import { BuildCustomCell, GetVendorCount } from './controller';
let initialFilter = {
  isTotalNeeded: true,
  coll: 'vendor_details',
  filter: {},
};
let apiURL = '/loadPageFromImages';
let savedObj = {};
let deletedVendorKey = {};
let dispItems = [];
let vendorCountObj = {};
let tableLoader;
let searchFilterRef = initialFilter;
const formatter = (pData, setTableLoader) => {
  pData.forEach((elm) => {
    if (elm.created_on) {
      elm.created_on = new Date(elm.created_on).toLocaleString();
    }
    if (elm.updated_on) {
      elm.updated_on = new Date(elm.updated_on).toLocaleString();
    }
    elm.is_correct = !!elm.is_correct;
  });
  setTableLoader(false);
  dispItems = pData;
  return pData;
};
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable no-script-url */

const saveUrlInfo = () => {
  if (!Object.keys(savedObj).length) {
    return;
  }
  http.makeCall('/saveURLInfo', 'post', savedObj, (err, res) => {
    if (err) {
      return console.log('saveURLInfo error');
    }
    savedObj = {};
  });
};
const removeVendorKey = (vendorObj, index) => {
  const vendorKeyList = Object.keys(deletedVendorKey);
  let dataToSend;
  const removeVendorCb = () => {
    http.makeCall('/remove_vendor_rule', 'post', dataToSend, (err, res) => {
      if (err) {
        return console.log('remove_vendor_rule error');
      }
      if (vendorKeyList.length) {
        const preIndex = {};
        vendorKeyList.forEach((key) => {
          preIndex[deletedVendorKey[key].index] = true;
        });
        const arr = [];
        dispItems.forEach((elm, dIndex) => {
          if (!preIndex[dIndex]) {
            arr.push(elm);
          }
        });
        dispItems = arr;
        tableLoader(false);
        deletedVendorKey = {};
      } else {
        dispItems.splice(index, 1);
        tableLoader(false);
      }
    });
  };
  if (vendorKeyList.length) {
    const vClean = [];
    vendorKeyList.forEach((key) => {
      vClean.push(deletedVendorKey[key].vendor_name_cleaned);
    });
    dataToSend = {
      vendor_key: vendorKeyList,
      vendor_name_cleaned: vClean,
      is_list: true,
    };
    // tslint:disable-next-line:max-line-length
    removeVendorCb();
  } else {
    if (!vendorObj) {
      return console.log('vendor key selected');
    }
    dataToSend = {
      vendor_key: vendorObj.vendor_key,
      vendor_name_cleaned: vendorObj.vendor_name_cleaned,
    };
    removeVendorCb();
  }
};
const saveAllChanges = () => {
  const isUrlAdded = Object.keys(savedObj).length;
  if (!isUrlAdded) {
    return (http.errorMsg = 'Please do some modification and then Save');
  }
  saveUrlInfo();
};
function insertSubstringBeforeDot(originalString, substringToInsert) {
  const dotIndex = originalString.indexOf('.');

  if (dotIndex !== -1) {
    // If dot is found, insert the substring before it
    const modifiedString =
      originalString.slice(0, dotIndex) +
      substringToInsert +
      originalString.slice(dotIndex);
    return modifiedString;
  } else {
    // If dot is not found, simply concatenate the substring at the end
    return originalString + substringToInsert;
  }
}

export default function VendorDetails() {
  const location = useLocation();
  const bData = CentralizedData.backend_data;
  const column = bData.routeJson.vendor.group.vendor_details.column;
  const [filter, setFilter] = useState(initialFilter);
  const [affectedTransaction, setAffectedTransaction] = useState(0);
  useEffect(() => {
    if (!vendorCountObj.total_affected_transactions) {
      GetVendorCount(vendorCountObj, () => {
        setAffectedTransaction(vendorCountObj.total_affected_transactions);
      });
    }
    return () => {
      savedObj = {};
      deletedVendorKey = {};
      dispItems = [];
      vendorCountObj = {};
    };
  }, []);
  if (!column.length) {
    return <div></div>;
  }
  const handleUpload = async (key, files, vendorInfo, msg, cb) => {
    try {
      let sendingObj = {};
      if (files) {
        const file = files[0];
        const rNo = Math.round(Math.random() * 900 + 100);
        const fileName = insertSubstringBeforeDot(file.name, rNo);
        // file.name = fileName;
        const fileRef = ref(storage, `icon/${fileName}`);
        await uploadBytes(fileRef, file);
        const url = await getDownloadURL(fileRef);
        sendingObj = { url, v_key: key, o_name: fileName };
      } else {
        const fileRef = ref(storage, `icon/${vendorInfo.o_name}`);
        await deleteObject(fileRef);
        sendingObj = { url: '', v_key: key, o_name: '' };
      }
      // console.log('downloadable links:', sendingObj)
      http.makeCall(
        '/saveImages',
        'post',
        sendingObj,
        (err, res) => {
          if (err) {
            return console.log('saveImages error');
          }
          cb(sendingObj);
        },
        msg,
      );
    } catch (error) {
      console.error('Error uploading files:', error);
      http.isLoader = false;
      http.notification = 'Error in uploading files: ' + error.message;
    } finally {
      console.log('done');
    }
  };
  const onFileChanged = (event, vendorInfo) => {
    const key =
      vendorInfo.vendor_key ||
      (vendorInfo.c_vendor_name || ``).replace(/ /g, ``);
    if (!key) {
      return;
    }
    http.isLoader = true;
    vendorInfo.is_correct = true;
    handleUpload(
      key,
      event.target.files,
      null,
      'successfully uploaded',
      (sendingObj) => {
        vendorInfo.o_name = sendingObj.o_name;
        setFilter({ ...searchFilterRef });
      },
    );
  };
  const onIconDelete = (vendorInfo) => {
    const key =
      vendorInfo.vendor_key ||
      (vendorInfo.c_vendor_name || ``).replace(/ /g, ``);
    if (!key) {
      return;
    }
    http.isLoader = true;
    handleUpload(key, null, vendorInfo, 'successfully deleted', () => {
      vendorInfo.o_name = '';
      setFilter({ ...searchFilterRef });
    });
  };
  BuildCustomCell(
    column,
    savedObj,
    deletedVendorKey,
    onFileChanged,
    onIconDelete,
  );
  const subscriber = (searchFilter, api) => {
    apiURL = api;
    searchFilterRef = searchFilter;
    setFilter(searchFilter);
  };
  const onEmptyIconClk = (flag) => {
    console.log('onEmptyIconClk', flag);
    if (flag) {
      searchFilterRef.filter.icon = { $exists: false };
    } else {
      searchFilterRef.filter.icon = { $exists: true };
    }
    setFilter({ ...searchFilterRef });
  };
  const currentPage = location.state && location.state.currentPage;
  return (
    <div>
      <CustomSearch
        currentPage={currentPage}
        subscriber={subscriber}
      ></CustomSearch>
      <div className="sm:flex sm:items-center">
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            onClick={() => {}}
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Total vendor
            {'    ' + vendorCountObj.vendor_count}
          </button>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            onClick={() => {}}
            className="block rounded-md bg-yellow-500 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Affected Transactions
            {'    ' + affectedTransaction}
          </button>
        </div>

        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            onClick={saveAllChanges}
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Save All Changes
          </button>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            onClick={removeVendorKey}
            className="block rounded-md bg-red-500 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Bulk Delete
          </button>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <div className="relative flex items-start">
            <div className="flex h-6 items-center">
              <CheckBox changeListner={onEmptyIconClk}></CheckBox>
            </div>
            <div className="ml-3 text-sm leading-6">
              <label htmlFor="comments" className="font-medium text-gray-900">
                Show only Empty icon
              </label>
            </div>
          </div>
        </div>
      </div>
      <ServerTable
        filters={filter}
        formatter={formatter}
        columns={column}
        apiURL={apiURL}
      ></ServerTable>
    </div>
  );
}
