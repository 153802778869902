import { useState } from "react";
const ruleRegex = new RegExp(`[&\/\\#,+()$~%.'"*?<>!_|{}@^]`, "g");
const CustomInput = ({ container, keyName, removeSpChar, onBlur, placeholder }) => {
  const [tVal, setText] = useState(container[keyName] || "");
  const onTextChange = (e) => {
    container[keyName] = e.target.value;
    if (removeSpChar) {
      container[keyName] = container[keyName].replace(ruleRegex, "");
    }
    setText(container[keyName]);
  };
  const placeholderText = placeholder || 'Enter Text'
  const iType = container.type || "text";
  if (onBlur) {
    <div className="mt-2">
      <input
        onChange={onTextChange}
        placeholder={placeholderText}
        onBlur={onBlur}
        type={iType}
        value={tVal}
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
      />
    </div>;
  }
  if (container.isReset) {
    delete container.isReset;
    return (
      <div className="mt-2">
        <input
          onChange={onTextChange}
          placeholder={placeholderText}
          type={iType}
          value={tVal}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        />
      </div>
    );
  }
  return (
    <div>
      <input
        onChange={onTextChange}
        placeholder={placeholderText}
        type={iType}
        value={container[keyName]}
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
      />
    </div>
  );
};
export default CustomInput;
