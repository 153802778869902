import { ArchiveBoxXMarkIcon } from "@heroicons/react/20/solid";
export const columnFormattar = (column, onDeleteClick) => {

    column.forEach(elm => {
        if (elm.selector === 'delete') {
            elm.cell = (row, index) => {
                // console.log('row ', row)
                return (<button
                    onClick={onDeleteClick.bind(null, row, index)}
                    type="button"
                    className="rounded-full redCol bg-indigo-600 p-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    <ArchiveBoxXMarkIcon className="h-5 w-5" aria-hidden="true" />
                </button>)
            }
        }
    });
};