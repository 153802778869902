import React, { useEffect, useState } from "react";
import CentralizedData from "../../../stores/data";
import CustomInput from "../../../components/form_elments/customInput";

import "../DataTable.css";
import { ArchiveBoxXMarkIcon } from "@heroicons/react/20/solid";

import { saveAllChanges, getFilename } from '../common'
import AddCategory from "./add_type";
import http from "../../../common/http";

let imageObj = {};



const DataTable = ({ activeItem }) => {
  const [manualAccountType, setmanualAccountType] = useState([]);
  useEffect(() => {
    http.makeCall(`/getAllData`, `post`, { page_name: `getTypeInfo` }, (err, res) => {
      if (err) {
        http.errorMsg = err.message || "Something went wrong";
        return console.log(`error = `, err);
      }
      setmanualAccountType(res.data.data);
      console.log(res.data)
    });
  }, []);
  const addSubType = (index, item) => {
    // setShowInput(!showInput);
    item.sub.push({ icon: "", type: " " });
    setmanualAccountType([...manualAccountType]);
  };

  const onFileChanged = (event, item, key) => {
    if (!item) {
      return;
    }
    item.icon = event.target.files;
    imageObj[key] = item;

  }

  const onSaveClick = () => {
    saveAllChanges(activeItem = 2, imageObj, manualAccountType, () => {
      imageObj = {}
      setmanualAccountType([...manualAccountType]);
    })
  }

  const delteSubType = (innIndex, rootIndex) => {
    const updatedData = [...manualAccountType];
    updatedData[rootIndex].sub.splice(innIndex, 1); // Remove the row at the specified index
    setmanualAccountType(updatedData);
  };

  const delteType = (index) => {
    const deletedData = [...manualAccountType];
    deletedData.splice(index, 1);
    setmanualAccountType(deletedData);
  };

  const addCatLink = (index) => {
    const obj = manualAccountType[index];
    while (obj.link.length <= obj.lsArr.length) {
      obj.link.push(``);
    }
    obj.lsArr.push(obj.lsArr.length);
    setmanualAccountType(JSON.parse(JSON.stringify(manualAccountType)));
  };
  return (
    <div className="tableTop">
      <div className="flex justify-end w-full">
        <AddCategory
          setmanualAccountType={setmanualAccountType}
          manualAccountType={manualAccountType}
        ></AddCategory>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <button
          style={{ height: "36px", width: "188px" }}
          className="flex justify-end items-center px-3 py-1 border border-blue-500 rounded hover:bg-gray-200"
          onClick={onSaveClick}
        >
          Save All Changes
        </button>
      </div>
      <br />
      <table className="dataTable">
        <thead>
          <tr>
            <th className="px-4 py-2 text-xs">Type</th>
            <th className="px-4 py-2 text-xs">Sub Type</th>
            <th className="px-4 py-2 text-xs">Icon</th>

            <th colspan="2" className="px-4 py-2 text-xs">
              Action
            </th>
          </tr>
        </thead>

        {manualAccountType.map((item, j) => (
          <tbody>
            <tr className="h-12 bg-gray-200">
              <td className="px-4 py-2 text-xs">
                <b>{item.type} </b>
              </td>
              <td></td>
              <td className="px-4 py-2 text-xs">
                <h6>{getFilename(item)}</h6>
                <input type="file" name="file" id="file" />
              </td>
              <td className="px-4 py-2 text-xs">
                <div className="row">
                  <button
                    onClick={() => addSubType(j, item)}
                    className=" items-center px-4 py-1 border border-green-500 rounded hover:bg-gray-200"
                  >
                    Add Sub Type
                  </button>
                  &nbsp;&nbsp;&nbsp;
                  <button
                    onClick={delteType.bind(null, j)}
                    className=" items-center px-4 py-1 border border-red-500 rounded hover:bg-gray-200"
                  >
                    Delete Type
                  </button>
                </div>
              </td>
            </tr>
            {item.sub.map((sub, i) => (
              <tr className="h-12">
                <td className="px-4 py-2 text-xs"></td>
                <td className="px-4 py-2 text-xs">
                  <CustomInput container={sub} keyName="type"></CustomInput>
                </td>

                <td className="px-4 py-2 text-xs">
                  <h6>{getFilename(sub)}</h6>
                  <input
                    type="file"
                    name="file"
                    id="file"
                    onChange={(event) => {
                      onFileChanged(event, item, item.type);
                    }}
                  />
                </td>

                <td className="deleteBtnCentre px-4 py-2 text-xs">
                  <button
                    onClick={() => delteSubType(i, j)}
                    className="flex justify-center  items-center px-3 py-1 border border-red-500 rounded hover:bg-gray-200"
                  >
                    <ArchiveBoxXMarkIcon className="h-4 w-4 mr-1" />
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        ))}
      </table>
    </div>
  );
};

export default DataTable;
