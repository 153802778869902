import { useState } from "react";
const SItem = ({ singleData, onLinkClick }) => {
  const [isGroup, setDropDown] = useState(!!singleData.group);
  if (!singleData.group) {
    return (
      <li onClick={onLinkClick.bind(null, singleData)}>
        <a
          href="javascript:void(0)"
          class="flex items-center p-2 text-base font-normal text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
        >
          <svg
            class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path>
          </svg>
          <span class="flex-1 ml-3 whitespace-nowrap">
            {singleData.disp_name}
          </span>
        </a>
      </li>
    );
  }

  const dropClass = isGroup ? "hidden py-2 space-y-2" : "py-2 space-y-2";
  const onDropClicked = () => {
    setDropDown(!isGroup);
  };
  const getInnerItem = () => {
    const inArr = Object.keys(singleData.group);
    return (
      <ul id="dropdown-example" className={dropClass}>
        {inArr.map((key) => {
          const elm = singleData.group[key];
          elm.s_key = key;
          return (
            <li onClick={onLinkClick.bind(null, elm)}>
              <a
                href="javascript:void(0)"
                class="flex items-center w-full p-2 text-base font-normal text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700 pl-11"
              >
                {elm.disp_name}
              </a>
            </li>
          );
        })}
      </ul>
    );
  };
  return (
    <li>
      <button
        type="button"
        class="flex items-center w-full p-2 text-base font-normal text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
        aria-controls="dropdown-example"
        data-collapse-toggle="dropdown-example"
      >
        <svg
          class="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z"
            clip-rule="evenodd"
          ></path>
        </svg>
        <span
          class="flex-1 ml-3 text-left whitespace-nowrap"
          sidebar-toggle-item
        >
          {singleData.disp_name}
        </span>
        <svg
          sidebar-toggle-item
          class="w-6 h-6"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
          onClick={onDropClicked}
        >
          <path
            fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </button>
      {!isGroup && getInnerItem()}
    </li>
  );
};
export default SItem;
