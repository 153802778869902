
import { Disclosure, Menu } from '@headlessui/react'
import { useState } from 'react';
import CustomCategory from './category'
import ManualAccount from './manual_account'


const activeClass = "inline-flex items-center border-b-2  px-1 pt-1 text-sm font-medium text-gray-900"
const inActiveClass = "inline-flex items-center border-b-2  px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
export default function Index() {
    const [activeItem, setActiveItem] = useState(1)
    const onLinkClick = () => {
        setActiveItem(activeItem === 1 ? 2 : 1)
    }
    const customActive = activeItem === 1 ? activeClass + " border-indigo-500" : inActiveClass
    const manualActive = activeItem === 2 ? activeClass + " border-indigo-500" : inActiveClass
    return (
        <div className="p-4">
            <Disclosure as="nav" className="bg-white shadow">
                <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                    <div className="relative flex h-16 justify-between">

                        <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                            <div className="flex flex-shrink-0 items-center">
                                <img
                                    className="h-8 w-auto"
                                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                                    alt="Your Company"
                                />
                            </div>
                            <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                                {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                                <a
                                    href="javascript:void(0)"
                                    onClick={onLinkClick}
                                    className={customActive}
                                >
                                    Custom Category
                                </a>
                                <a
                                    href="javascript:void(0)"
                                    onClick={onLinkClick}
                                    className={manualActive}
                                >
                                    Manual Account Type
                                </a>

                            </div>
                        </div>
                        <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">


                            {/* Profile dropdown */}
                            {/* <Menu as="div" className="relative ml-3">
                                <button className=" items-center px-4 py-1 border border-green-500 rounded hover:bg-gray-200">

                                    Save All Changes
                                </button>
                            </Menu> */}
                        </div>
                    </div>
                </div>

            </Disclosure>
            {activeItem === 1 ? (<CustomCategory activeItem={activeItem}></CustomCategory>) : <ManualAccount activeItem={activeItem}></ManualAccount>}
        </div>

    )
}
