import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './routes/AppRoutes';
import { StoreProvider } from './store'
const App = () => {
  return (
    <StoreProvider>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </StoreProvider>
  )
}
export default App;