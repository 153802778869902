import CustomInput from "../../../components/form_elments/customInput";
import { PlusIcon as PlusOutline } from '@heroicons/react/20/solid';
import { MinusIcon as MinusSolid } from '@heroicons/react/20/solid';
export const getSubcategory = (sub, i) => {
  if (!sub[i]) {
    // sub[i] = {}
    return ''
  }
  return (
    <CustomInput container={sub[i]} keyName={"cat"} placeholder="Enter category"></CustomInput>
  )
}
export const BuildCatData = (catData) => {
  catData.forEach(elm => {
    elm.link = elm.link || [];
    const lLen = elm.link.length;
    const len = elm.sub.length > lLen ? elm.sub.length : lLen;
    const arr = [];
    for (let i = 0; i < len; i += 1) {
      if (elm.sub[i]) {
        elm.sub[i].link = elm.sub[i].link || [];
      }
      arr.push(i);
    }
    elm.lsArr = arr;
  });
}
export const getRange = (num) => {
  const arr = [];
  for (let i = 0; i < num; i += 1) {
    arr.push(i);
  }
  return arr;
}
export const getLinkInput = (link, i) => {
  if (link[i]) {
    return <CustomInput container={link} keyName={i}></CustomInput>
  }
  return ''
}

export const getFilename = (sub) => {
  if (sub) {
    let name = ''
    if (typeof sub.icon === 'object') {
      const iObj = sub.icon[0]
      if (iObj) {
        name = iObj.name
      }
    } else {
      name = sub.icon
    }
    return name
  }
  return ''
}

