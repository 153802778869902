const CentralizedData = {
  backend_data: {
    generalUsers: [],
    usersBankDetails: [],
    routeJson: {
      link_order: [],
      users: {
        column: []
      }
    },
  },
  catData: []
};
export default CentralizedData;
export const nwsList = [
  {
    label: 'Needs',
    value: 'needs',
    id: 1
  },
  {
    label: 'Wants',
    value: 'wants',
    id: 2
  },
  {
    label: 'Saving',
    value: 'savings',
    id: 3
  },
  {
    label: 'Blank',
    value: 'blank',
    id: 4
  },
];
