import { useEffect, useState } from "react";
import CentralizedData from "../../stores/data";
import ServerTable from "../../components/atoms/serverTable";
import CustomSearch from "../search";
import { useLocation } from 'react-router-dom';
const initialFilter = {
  isTotalNeeded: true,
  coll: "account_details",
  filter: {},
};
let apiURL = 'loadMorePageData';
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable no-script-url */
const formatter = (pData, setTableLoader) => {
  pData.forEach((elm) => {
    elm.institution_id = elm.item.institution_id;
    elm.bank_name = elm.c_details && elm.c_details.c_bank_name;
    elm.no_of_accounts = elm.accounts.length;
    elm.item_id = elm.item.item_id;
  });
  setTableLoader(false);
  return pData;
};
export default function Users() {
  const location = useLocation();
  const bData = CentralizedData.backend_data;
  const column = bData.routeJson.banks.column;
  const [filter, setFilter] = useState(initialFilter);
  useEffect(() => { }, []);
  if (!column.length) {
    return <div></div>;
  }
  const subscriber = (searchFilter, api) => {
    apiURL = api;
    setFilter(searchFilter);
  };
  const currentPage = location.state && location.state.currentPage;
  return (
    <div>
      <CustomSearch currentPage={currentPage} subscriber={subscriber}></CustomSearch>
      <ServerTable filters={filter} formatter={formatter} columns={column} apiURL={apiURL}></ServerTable>
    </div>
  );
}
