import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import http from "../../common/http";
let totalRows = 0;
let currentPage = 1;
let data = [];
const ServerTable = ({
  apiURL,
  columns,
  filters,
  hidePagination = false,
  formatter,
}) => {
  const [loading, setLoading] = useState(false);
  const fetchData = async () => {
    setLoading(true);
    console.log('filter = ', JSON.stringify(filters));
    http.makeCall(
      apiURL || "/loadMorePageData",
      "post",
      filters,
      (err, res) => {
        if (err) {
          // TODO handle error
          return console.log(`error = `, err);
        }
        const pageData = res.data.pageData;
        if (formatter) {
          pageData.pData = formatter(pageData.pData, setLoading);
        } else {
          setLoading(false);
        }
        data = pageData.pData;
        totalRows = pageData.totalCount || 100;
        // setTotalRows(pageData.totalCount || 100);

      }
    );
  };
  const handlePageChange = (page) => {
    const newPerPage = filters.offset || 10;
    filters.startPos = page - 1 > 0 ? (page - 1) * newPerPage : 0;
    fetchData();
    // setCurrentPage(page);
    currentPage = page;
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    filters.offset = newPerPage;
    filters.startPos = page - 1 > 0 ? (page - 1) * newPerPage : 0;
    fetchData();
    // setPerPage(newPerPage);
  };
  const handleSort = async (column, sd) => {
    const order = sd === "asc" ? -1 : 1;
    const sort = {};
    sort[column.selector] = order;
    filters.sort = sort;
    fetchData()
  };

  useEffect(() => {
    if (!data.length) {
      fetchData();
    }
    return () => {
      totalRows = 0;
      currentPage = 1;
      data = [];
    };
  }, [filters]);
  return (
    <div>
      <DataTable
        columns={columns}
        data={data}
        progressPending={loading}
        pagination={!hidePagination}
        paginationServer
        paginationTotalRows={totalRows}
        paginationDefaultPage={currentPage}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        sortServer
        highlightOnHover
        onSort={handleSort}
        striped={true}

        fixedHeader
      />
    </div>
  );
};
export default ServerTable;
