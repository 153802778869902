import { useRoutes, Navigate } from 'react-router-dom';
import { useStore } from '../store';
import Layout from "../components/layout";
import Error404 from '../modules/errors/404';
// Logged out routes
import Login from '../modules/auth/login';
// Logged in routes
import Users from '../modules/users';
import UsersBank from '../modules/users_bank';
import Settings from '../modules/settings';
import Accounts from '../modules/accounts';
import Transaction from '../modules/transaction';
import VendorCleanup from '../modules/vendor_cleanup';
import VendorDetails from '../modules/vendor_details';
import NWSByCategory from '../modules/nws_by_category';
import BankDetails from '../modules/bank_details'
import NWSByVendor from '../modules/nws_by_vendor'
import Enable_features from '../modules/enable_features';
const AppRoutes = () => {
    const { layoutStore } = useStore();
    const renderDash = (element) => {
        return (<Layout>{element}</Layout>);
    };
    const defaultRoutes = [{ path: '/404', element: <Error404 /> }];
    const publicRoutes = [
        {
            path: '/login',
            element: <Login />
        },
        {
            path: '/',
            element: <Navigate to="/login" />
        },
        {
            path: '*',
            element: <Navigate to="/login" />
        }
    ];
    const privateRoutes = [
        {
            path: '/users',
            element: renderDash(<Users />)
        },
        {
            path: '/nws_by_vendor',
            element: renderDash(<NWSByVendor />)
        },
        {
            path: '/settings',
            element: renderDash(<Settings />)
        },
        {
            path: '/banks',
            element: renderDash(<UsersBank />)
        },
        {
            path: '/bank_details',
            element: renderDash(<BankDetails />)
        },
        {
            path: '/nws_by_category',
            element: renderDash(<NWSByCategory />)
        },
        {
            path: '/accounts',
            element: renderDash(<Accounts />)
        },
        {
            path: '/transactions',
            element: renderDash(<Transaction />)
        },
        {
            path: '/vendor_cleanup',
            element: renderDash(<VendorCleanup />)
        },
        {
            path: '/vendor_details',
            element: renderDash(<VendorDetails />)
        },
        {
            path: '/enable_features',
            element: renderDash(<Enable_features />)
        },
        {
            path: '/',
            element: <Navigate to="/users" />
        },
        {
            path: '*',
            element: <Navigate to="/404" />
        }
    ];

    const is_authenticated = layoutStore.isLoggedIn;
    const routes = useRoutes([
        ...(is_authenticated ? privateRoutes : publicRoutes),
        ...defaultRoutes
    ]);
    return routes;
}
export default AppRoutes;
