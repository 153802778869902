import { useEffect, useState } from "react";
import CentralizedData from "../../stores/data";
import ServerTable from "../../components/atoms/serverTable";
import CustomSearch from "../search";
import { useLocation } from 'react-router-dom';
import { columnFormattar, formatter } from './helper';
import BulkSelectUpdate from './bulk_select_update';
import { updateBulkRow } from './helper'
const initialFilter = {
    isTotalNeeded: true,
    coll: "plaid_cat_list",
    filter: {},
};
let updatedCatObj = {}
let apiURL = 'loadMorePageData';
let catMap = {};
let bulkContainer = {};
export default function Index() {
    const location = useLocation();
    const bData = CentralizedData.backend_data;
    const column = bData.routeJson.nws.group.nws_by_category.column;
    const [filter, setFilter] = useState(initialFilter);
    useEffect(() => {
        return () => {
            updatedCatObj = {};
            catMap = {};
            bulkContainer = {};
        }
    }, []);
    if (!column.length) {
        return <div></div>;
    }
    const categorySelection = (catObj) => {
        if (updatedCatObj[catObj.category_id]) {
            delete this.updatedCatObj[catObj.category_id];
        } else {
            updatedCatObj[catObj.category_id] = catObj;
        }
    }
    const onSelectionChange = (item, cKey) => {
        console.log(item, cKey);
        if (!updatedCatObj[item.category_id]) {
            updatedCatObj[item.category_id] = {};
        }
        if (item[cKey] === "blank") {
            item[cKey] = null;
        }
        if (cKey === 'def_cat') {
            item.def_sub_cat = null;
            updatedCatObj[item.category_id].def_sub_cat = null;

        }
        updatedCatObj[item.category_id][cKey] = item[cKey];
    };
    const isBlankPresent = bData.catData[bData.catData.length - 1].cat === 'blank'
    bData.catData.forEach((elm) => {
        catMap[elm.cat] = elm;
        if (elm.sub && !isBlankPresent) {
            elm.sub.push({ cat: 'blank' })
        }
    });
    if (!isBlankPresent) {
        bData.catData.push({ cat: 'blank' })
    }
    columnFormattar(column, bData.catData, catMap, onSelectionChange, categorySelection)
    const subscriber = (searchFilter, api) => {
        apiURL = api;
        setFilter(searchFilter);
    };
    const onBulkSelectChange = (updatedContainer, cKey) => {
        updateBulkRow(updatedContainer, updatedCatObj, cKey)
    }
    const currentPage = location.state && location.state.currentPage;
    return (
        <div>
            <CustomSearch currentPage={currentPage} subscriber={subscriber}></CustomSearch>
            <BulkSelectUpdate updatedCatObj={updatedCatObj} callingFn={onBulkSelectChange} container={bulkContainer} catMap={catMap} catData={bData.catData}></BulkSelectUpdate>
            <ServerTable filters={filter} formatter={formatter} columns={column} apiURL={apiURL}></ServerTable>
        </div>
    );
}
