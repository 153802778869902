import axios from 'axios';
import { observable, action, makeObservable } from "mobx";
import CentralizedData from "../stores/data"
const fileUploadOptions =  {
	headers: {
		'Content-Type': 'multipart/form-data'
	}
}

/* eslint-disable import/no-anonymous-default-export */
class HTTPCall {
	isLoader = false
	errorMsg = ''
	notification = ''
	constructor() {
		makeObservable(this, {
			isLoader: observable,
			errorMsg: observable,
			notification: observable,
			getInitialData: action,
			makeCall: action,
		});
	}

	
	getInitialData = (cb) => {
		let uData = window.localStorage.getItem("token");
		uData = JSON.parse(uData)
		if (!uData || !uData.email) {
			return cb(new Error("no user"))
		}
		this.makeCall(`/authenticate`, `post`, uData, (err, res) => {
			if (err) {
				return cb(new Error(err.message || "something went wrong"))
			}
			if (!CentralizedData.backend_data.routeJson.link_order.length) {
				for (let key in res.data.routeJson) {
					const obj = res.data.routeJson[key]
					obj.s_key = key
				}
				console.log('CentralizedData.backend_data.routeJson', res.data.routeJson)
				CentralizedData.backend_data.catData = res.data.catData;
				CentralizedData.backend_data.routeJson = res.data.routeJson
				console.log(res.data.routeJson)
			}
			cb(null)

		});
	}
	makeCall = (url, method, dataToSend, cb, notification) => {
		const formattedData = { data: dataToSend }
		this.isLoader = true
		axios[method](url, formattedData)
			.then((response) => {
				this.isLoader = false
				if (notification) {
					this.notification = notification || "Successfully saved";
				}
				cb(null, response)
				// Handle data
			})
			.catch((error) => {
				this.isLoader = false
				this.errorMsg = "Error in API " + url + " " + (error.message || "something went wrong");
				console.log(error);
			})
	}
	makeCallForFileUpload = (url, method, dataToSend, cb, notification) => {
		this.isLoader = true
		axios[method](url, dataToSend, fileUploadOptions)
			.then((response) => {
				this.isLoader = false
				if (notification) {
					this.notification = notification || "Successfully saved";
				}
				cb(null, response)
			})
			.catch((error) => {
				this.isLoader = false
				this.errorMsg = "Error in API " + url + " " + (error.message || "something went wrong");
				console.log(error);
			})
	}
}
export default new HTTPCall();
