import { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'



function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
const registerFnObj = {
    def_cat: true,
    def_sub_cat: true,
    def_nws: true
}
export default function CustomSelect({ itemList, label, value, catMap, onSelectionChange, container, cKey }) {

    const dLabel = label || 'label'
    const dValue = value || 'value'
    const [selected, setSelected] = useState(container[cKey] || '');
    const [dispItem, setDispItem] = useState(itemList)
    // console.log('selected ', selected, dLabel, dValue)
    const onValueChange = (pickItem) => {
        console.log('pick item', pickItem)
        container[cKey] = pickItem
        setSelected(pickItem)
        onSelectionChange(container, cKey)
        if (cKey === 'def_cat' && container.def_sub_cat_fn) {
            container.def_sub_cat_fn()
        }

    }
    if (registerFnObj[cKey]) {
        container[cKey + '_fn'] = () => {
            if (cKey === 'def_sub_cat') {
                const newItemList = (catMap[container.def_cat] && catMap[container.def_cat].sub) || []
                setDispItem(newItemList)
            }

            setSelected(container[cKey])
        }
    }
    return (
        <Listbox value={selected} onChange={onValueChange}>
            {({ open }) => (
                <>
                    <div className="relative mt-2">
                        <Listbox.Button className="fixed-width-button relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                            <span className="flex items-center">
                                <span
                                    aria-label='Online'
                                    className={classNames(
                                        'bg-green-400',
                                        'inline-block h-2 w-2 flex-shrink-0 rounded-full'
                                    )}
                                />
                                <span className="ml-3 block truncate">{selected === 'blank' ? '' : selected}</span>
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {dispItem.map((item) => (
                                    <Listbox.Option
                                        key={item.id}
                                        className={({ active }) =>
                                            classNames(
                                                active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                                                'relative cursor-default select-none py-2 pl-3 pr-9'
                                            )
                                        }
                                        value={item[dValue]}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <div className="flex items-center">
                                                    <span
                                                        className={classNames(
                                                            'bg-green-400',
                                                            'inline-block h-2 w-2 flex-shrink-0 rounded-full'
                                                        )}
                                                        aria-hidden="true"
                                                    />
                                                    <span
                                                        className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                                                    >
                                                        {item[dLabel]}
                                                    </span>
                                                </div>

                                                {selected ? (
                                                    <span
                                                        className={classNames(
                                                            active ? 'text-white' : 'text-indigo-600',
                                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                                        )}
                                                    >
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    )
}
