import { Fragment } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  HomeIcon,
  ArrowRightOnRectangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";

import { useStore } from "../../store";
import CentralizedData from "../../stores/data";
import http from "../../common/http";
import SItem from "./sidebarItem";
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable no-script-url */
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
let routeJson = {};
const routerMapInfo = {
  banks: {
    route: "/banks",
  },
  bank_details: {
    route: "/bank_details",
  },
  users: {
    route: "/users",
  },
  accounts: {
    route: "/accounts",
  },
  nws_by_vendor: {
    route: "/nws_by_vendor",
  },
  transactions: {
    route: "/transactions",
  },
  vendor_cleanup: {
    route: "/vendor_cleanup",
  },
  vendor_details: {
    route: "/vendor_details",
  },
  nws_by_category: {
    route: "/nws_by_category",
  },
  enable_features: {
    route: "/enable_features",
  },
};
const Sidebar = ({ sidebarOpen, setSidebarOpen }) => {
  const [navigation, setNavigation] = useState(routeJson);
  const navigate = useNavigate();
  const location = useLocation();
  const { layoutStore } = useStore();
  const buildUpIcon = (routeJson) => {
    const currentPath = location.pathname;
    for (let key in routeJson) {
      const obj = routeJson[key];
      obj.icon = HomeIcon;
      if (currentPath.includes(key)) {
        obj.current = true;
      }
    }
  };
  if (CentralizedData.backend_data.routeJson.link_order.length) {
    buildUpIcon(CentralizedData.backend_data.routeJson);
  }
  useEffect(() => {
    if (!CentralizedData.backend_data.routeJson.link_order.length) {
      http.getInitialData((err) => {
        // TODO handle err
        if (!err) {
          setNavigation(CentralizedData.backend_data.routeJson);
        }
      });
    }
  }, [location]);
  const onLinkClick = (singleData) => {
    const rInfo = routerMapInfo[singleData.s_key];
    if (!rInfo) {
      return;
    }
    // const currentPath = location.pathname;
    for (let key in navigation) {
      const obj = navigation[key];
      obj.icon = HomeIcon;
      if (rInfo.route === "/" + key) {
        obj.current = true;
      } else {
        obj.current = false;
      }
    }
    navigate(rInfo.route, { state: { currentPage: singleData.s_key } });
    setSidebarOpen(false);
  };
  const getSidebarSingle = (key) => {
    const singleData = navigation[key];
    if (!singleData) {
      return <div></div>;
    }
    return (
      <SItem singleData={singleData} onLinkClick={onLinkClick}></SItem>
    );
  };
  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                  <div className="flex flex-shrink-0 items-center px-4">
                    <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                      alt="Your Company"
                    />
                  </div>
                  {/* <nav className="mt-5 space-y-1 px-2">
                    {navigation.link_order &&
                      navigation.link_order.map(getSidebarSingle)}
                  </nav> */}
                  <div class="max-w-2xl mx-auto">
                    <aside class="w-64" aria-label="Sidebar">
                      <div class="px-3 py-4 overflow-y-auto rounded bg-gray-50 dark:bg-gray-800">
                        <ul class="space-y-2">
                          {navigation.link_order &&
                            navigation.link_order.map(getSidebarSingle)}
                        </ul>
                      </div>
                    </aside>
                  </div>
                </div>
                <div className="flex flex-shrink-0 border-t border-gray-200 p-4">
                  <a
                    href="javascript:void(0)"
                    className="group block flex-shrink-0"
                  >
                    <div className="flex items-center">
                      <div>
                        <img
                          className="inline-block h-10 w-10 rounded-full"
                          src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                          alt=""
                        />
                      </div>
                      <div className="ml-3">
                        <p className="text-base font-medium text-gray-700 group-hover:text-gray-900">
                          Tom Cook
                        </p>
                        <p className="text-sm font-medium text-gray-500 group-hover:text-gray-700">
                          View profile
                        </p>
                      </div>
                    </div>
                  </a>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white">
          <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
            <div className="flex flex-shrink-0 items-center px-4">
              <img
                className="h-8 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                alt="Your Company"
              />
            </div>
            {/* <nav className="mt-5 flex-1 space-y-1 bg-white px-2">
              {navigation.link_order &&
                navigation.link_order.map(getSidebarSingle)}
            </nav> */}
            <div class="max-w-2xl mx-auto">
              <aside class="w-64" aria-label="Sidebar">
                <div class="px-3 py-4 overflow-y-auto rounded bg-gray-50 dark:bg-gray-800">
                  <ul class="space-y-2">
                    {navigation.link_order &&
                      navigation.link_order.map(getSidebarSingle)}
                  </ul>
                </div>
              </aside>
            </div>
          </div>
          <div className="flex flex-shrink-0 border-t border-gray-200 p-4">
            <a
              href="javascript:void(0)"
              className="group block w-full flex-shrink-0"
            >
              <div className="flex items-center">
                <div>
                  <img
                    className="inline-block h-9 w-9 rounded-full"
                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                    Tom Cook
                  </p>
                  <p
                    onClick={() => {
                      layoutStore.logout();
                      navigate("/login");
                    }}
                    className="text-xs font-medium text-red-500 group-hover:text-red-700 hover:underline"
                  >
                    <ArrowRightOnRectangleIcon
                      className="inline-block h-4 w-4"
                      aria-hidden="true"
                    />{" "}
                    Logout
                  </p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Sidebar;
